/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "@fontsource/inter/300.css"
import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"

//import "fontsource-lato/400.css"
//import "fontsource-lato/700.css"

// import "@fontsource/montserrat/300.css"
// import "@fontsource/montserrat/400.css"
// import "@fontsource/montserrat/500.css"
// import "@fontsource/montserrat/600.css"

import "@fontsource/poppins/300.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"
//import "@fontsource/poppins/700.css"

import "./src/styles/normalize.scss"
import "./src/styles/index.scss"
import "./src/styles/publications.scss"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import "react-day-picker/lib/style.css"
import "./src/styles/calendar.scss"

import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)
