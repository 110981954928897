import React from "react"
import WhiteLink from "../buttons/whitelink"
import useFooterLinks from "../../hooks/footerlinks"
import useSiteMetadata from "../../hooks/sitemetadata"
import ColorLink from "../buttons/colorlink"

const FooterLinks = () => {
  const links = useFooterLinks()
  const { sharedInstrumentLink } = useSiteMetadata()

  const ret: Array<any> = links.map((link: any, index: number) => {
    return (
      <li className="mb-2" key={index}>
        <WhiteLink aria-label={`Goto ${link.name}`} to={link.link}>
          {link.name}
        </WhiteLink>
      </li>
    )
  })

  ret.push(
    <li className="mb-2" key="booking">
      <ColorLink
        color="white"
        aria-label={`Goto ${sharedInstrumentLink.name}`}
        to={sharedInstrumentLink.url}
      >
        {sharedInstrumentLink.name}
      </ColorLink>
    </li>
  )

  return <ul>{ret}</ul>
}

export default FooterLinks
