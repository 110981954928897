// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-adminstafftemplate-tsx": () => import("./../../../src/templates/adminstafftemplate.tsx" /* webpackChunkName: "component---src-templates-adminstafftemplate-tsx" */),
  "component---src-templates-allfacultytemplate-tsx": () => import("./../../../src/templates/allfacultytemplate.tsx" /* webpackChunkName: "component---src-templates-allfacultytemplate-tsx" */),
  "component---src-templates-alllabstemplate-tsx": () => import("./../../../src/templates/alllabstemplate.tsx" /* webpackChunkName: "component---src-templates-alllabstemplate-tsx" */),
  "component---src-templates-caleventstemplate-tsx": () => import("./../../../src/templates/caleventstemplate.tsx" /* webpackChunkName: "component---src-templates-caleventstemplate-tsx" */),
  "component---src-templates-coursestemplate-tsx": () => import("./../../../src/templates/coursestemplate.tsx" /* webpackChunkName: "component---src-templates-coursestemplate-tsx" */),
  "component---src-templates-indextemplate-tsx": () => import("./../../../src/templates/indextemplate.tsx" /* webpackChunkName: "component---src-templates-indextemplate-tsx" */),
  "component---src-templates-jobstemplate-tsx": () => import("./../../../src/templates/jobstemplate.tsx" /* webpackChunkName: "component---src-templates-jobstemplate-tsx" */),
  "component---src-templates-newstemplate-tsx": () => import("./../../../src/templates/newstemplate.tsx" /* webpackChunkName: "component---src-templates-newstemplate-tsx" */),
  "component---src-templates-peopletemplate-tsx": () => import("./../../../src/templates/peopletemplate.tsx" /* webpackChunkName: "component---src-templates-peopletemplate-tsx" */),
  "component---src-templates-publicationstemplate-tsx": () => import("./../../../src/templates/publicationstemplate.tsx" /* webpackChunkName: "component---src-templates-publicationstemplate-tsx" */),
  "component---src-templates-researchareastemplate-tsx": () => import("./../../../src/templates/researchareastemplate.tsx" /* webpackChunkName: "component---src-templates-researchareastemplate-tsx" */),
  "component---src-templates-sitemaptemplate-tsx": () => import("./../../../src/templates/sitemaptemplate.tsx" /* webpackChunkName: "component---src-templates-sitemaptemplate-tsx" */)
}

