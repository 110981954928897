import React from "react"
import { Link } from "gatsby"

type LinkProps = {
  to: string
  target?: string
  aria?: string
  onClick?: any
  className?: string
  activeClassName?: string
}

const BaseLink: React.FC<LinkProps> = ({
  to,
  target,
  aria,
  onClick,
  className,
  activeClassName,
  children,
}) => {
  if (to === null || to === undefined) {
    to = ""
  }

  // Determine if link appears to be to an external site or not
  const isExt =
    !to.startsWith("/") || to.startsWith("http") || to.startsWith("www")

  if (isExt) {
    return (
      <a
        href={to}
        aria-label={aria}
        target={target}
        className={className}
        onClick={onClick}
      >
        {children}
      </a>
    )
  } else {
    return (
      <Link
        to={to}
        aria-label={aria}
        className={className}
        activeClassName={activeClassName}
        onClick={onClick}
      >
        {children}
      </Link>
    )
  }
}

BaseLink.defaultProps = {
  className: "",
  activeClassName: "",
  target: "_blank",
  aria: "",
}

export default BaseLink
